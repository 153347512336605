import Lottie from 'lottie-react';
import spiner from './SpinnerITProfis.json';

export const Spinner = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100vw',
        height: '100vh',
        background: '#1f2024'
      }}
    >
      <Lottie
        animationData={spiner}
        loop={true}
        style={{
          width: '150px',
          height: '150px',
        }}
      />
    </div>
  );
};
