import { FC } from 'react'
import Lottie from 'lottie-react';
import spinner from './loader.json';

interface Props {
  isES: boolean
}

export const SpinnerBttn: FC<Props> = ({ isES }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <Lottie
        animationData={spinner}
        loop={true}
        style={{
          width: '40px',
          marginRight: '10px'
        }}
      />
      { isES ? 'Cargando...' : 'Loading...'}
      
    </div>
  )
}
