export const getBytesToMB = ( bytes: number ) => {

  /**
    * 1 B      = 0.000001 MB
    * 424645 B = X
  */

  return (bytes * 0.000001);

}

export const formatCurrency = ( value: string, currency: string = 'USD' ) => {
  const numFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
    maximumFractionDigits: 2
  });

  return numFormat.format(Number(value)).toString();
}