import { DetailContact, FormContact } from '../';
import { languajes } from '../../helpers/languajes';
import { useAppSelector } from '../../store/hook';

const { contact } = languajes;
const { title, content } = contact;

export const ContactSection = () => {

  const { isES } = useAppSelector((state) => state.languaje);

  return (
    <section id="contact-us" className="contact-section wf-section">
      <div className="container-max w-container">
        <div className="info-columns w-row">
          <div className="column contact-left w-col w-col-6 w-col-stack">
            <div className="div-text">
              <h2 className="title-section">
                {isES ? title.es : title.en}
              </h2>
              <div>
                <p className="paragraph">
                  {isES ? content.es : content.en}
                </p>
              </div>
            </div>
            <FormContact />
          </div>
          {/*  =================== DETALLES DEL CONTACTO ========================== */}
          <DetailContact />
        </div>
      </div>
    </section>
  );
};
