export const languajes = {
  navbar: {
    inicio: { es: 'Inicio', en: 'Home' },
    servicio: { es: 'Servicios', en: 'Services' },
    contacto: { es: 'Contacto', en: 'Contact' },
    aplica: { es: 'Aplica con nosotros', en: 'Apply with us' },
    languaje: {
      spanish: { es: ' Español', en: ' Español' },
      english: { es: ' English', en: ' English' },
    },
  },
  home: {
    main: {
      and: { es: ' e ', en: ' and ' },
      bussiness: { es: 'Inteligencia de Negocios', en: 'Business Intelligence' },
      learn: { es: 'Conocer más', en: 'Learn more' },
      software: {
        title: {
          es: 'Software a la Medida',
          en: 'Custom Software',
        },
        content: {
          es: `Desarrollamos soluciones de software a la medida de sus necesidades, con
          los mejores estándares de calidad y sobre las bases tecnologías más
          utilizadas en el mercado.`,
          en: 'We develop software solutions tailored to your needs, with the best quality standards and based on the most widely used technologies in the market.',
        },
      },
      outsourcing: {
        title: {
          es: 'Outsourcing',
          en: 'Outsourcing',
        },
        content: {
          es: `Ponemos a disposición equipo de analistas de calidad, gestores de
          proyectos y desarrolladores en la tecnología que requiera como personal
          outsourcing, por el tiempo que lo necesite y de la forma que más le
          convenga.`,
          en: 'We make available a team of quality analysts, project manager and developers in the technology that you require as outsourcing staff, for as long as you need and in the most convenient way.',
        },
      },
      intelligence: {
        title: {
          es: 'Inteligencia de Negocios',
          en: 'Business Intelligence',
        },
        content: {
          es: `Una organización moderna necesita de herramientas de TI eficaces
          preparadas para ocuparse de datos y requisitos empresariales tan
          variables, que faciliten la toma de decisiones. Nosotros le facilitamos
          una.`,
          en: 'A modern organization needs powerful IT tools prepared to deal with highly variable data and business requirements that facilitate decision making. We can provide you with one.',
        },
      },
    },
    whoAreSection: {
      title: {
        who: { es: '¿Quiénes somos?', en: 'About us' },
        // are: { es: 'somos?', en: 'us' },
      },
      content: {
        paragraph1: {
          es: `Somos una empresa de servicios informáticos, dedicada al diseño, desarrollo, implementación de soluciones de software y automatización de procesos, para empresas e instituciones que buscan implementar las tecnologías de la información como herramientas para innovar, incrementar, mejorar y ser más eficientes en la ejecución de sus procesos y los servicios que prestan.`,
          en: `We are an IT company dedicated to the design, develop, implementation and quality assurance of software solutions and process automations, for enterprises and organizations that desire to implement IT as part of their tools to innovate, improve and thrive in the effectiveness of their process executions and the services they provide.`,
        },
        paragraph2: {
          es: `IT Profis está presente en el mercado tecnológico desde hace casi una década, y con nuestra casa matriz en Guatemala con extensión en El Salvador, poseemos un amplio y experimentado equipo de trabajo, con sólidos conocimientos en las más actualizadas tecnologías para el desarrollo de software e inteligencia de negocios en el área de informática y de servicios financieros.`,
          en: 'ITProfis has been present in the technology market for almost a decade and with our headquarters in Guatemala with an extension in El Salvador, we count with a wide and experienced work team, with advance knowledge in the latest technologies for software development and business intelligence products related to the financial services industry.',
        },
        paragraph3: {
          es: `El crecimiento constante que hemos tenido desde nuestra fundación y la confianza que nuestros clientes han depositado en nosotros a lo largo de todos estos años, han sido la base para consolidarnos como una de las mejores empresas en el rubro, con una fuerte presencia en el mercado local que nos permite proyectarnos hacia el mundo entero.`,
          en: 'The constant growth that we maintain through the years since our foundation, and the confidence from our customers, is our key element to consolidate ourselves as one of the best companies in the field with a well-known presence in the local market that allow us to transcend to the rest of the world.',
        },
      },
    },
    valueSection: {
      title: {
        proposition: { es: 'Propuesta', en: 'Value' },
        value: { es: 'De Valor', en: 'PROPOSITION' },
      },
      content: {
        main: {
          es: `Nuestro amplio equipo de trabajo que se caracteriza por tener la más alta calidad en estándares de ingeniería de software, tiene el compromiso de ofrecer los mejores servicios y productos para satisfacer las necesidades de su negocio o empresa. Trabajamos acorde a las metas y prioridades de su organización. Algunas de las tecnologías líderes en el mercado global que utilizamos para el desarrollo de nuestros servicios y productos son JavaScript, HTML/CSS, Java, SQL Server; Frameworks como React, Microsoft .NET, entre otros.`,
          en: 'Our large work team specializes in having the highest quality standards of software engineering, the compromise to offer the best product or service to satisfy the needs of your business or company. We work accordingly to your priorities and the goals you have set for your organization to meet and surpass your expectations. Some of the leading technologies in the market that we use to develop our products and services are JavaScript, HTML/CSS, Java, SQL Server; Frameworks as React, Microsoft .NET, to name a few.',
        },
        ourVision: {
          title: { es: 'Nuestra Visión', en: 'Our vision' },
          content: {
            es: `Ser reconocidos como una compañía confiable al ofrecer las mejores soluciones en el área de la tecnología de la información, en un mercado regional con proyección internacional.`,
            en: 'Being recognized as a reliable company in offering the best solutions in the IT field in a local market with an international projection.',
          },
        },
        ourMision: {
          title: { es: 'Nuestra Misión', en: 'Our mission' },
          content: {
            es: `Brindar a las empresas soluciones informáticas a la medida con
          alta calidad y tecnologías modernas, poner a su servicio
          consultores calificados que satisfagan plenamente las necesidades
          informáticas de nuestros clientes.`,
            en: 'Provide companies with high-quality customized IT solutions and modern technologies, put qualified consultants at your service to fully satisfy the IT needs of our clients.',
          },
        },
      },
    },
    mapsSection: {
      title: { our: { es: 'Nuestro', en: 'Our' }, scope: { es: 'Alcance', en: 'Scope' } },
      mexico: { es: 'México', en: 'Mexico' },
      centralAme: { es: 'Centroamérica', en: 'Central America' },
    },
    sloganSection: {
      software: { es: 'Desarrollo de Software e', en: 'Software and' },
      business: { es: 'Inteligencia de Negocios', en: 'Business Intelligence' },
    },
    banner: {
      es: 'HACEMOS DE SUS NECESIDADES NUESTRA PRIORIDAD.',
      en: 'WE MAKE YOUR NEEDS OUR PRIORITY.',
    },
  },
  service: {
    main: {
      title: {
        our: { es: 'Nuestros', en: 'Our' },
        service: { es: 'Servicios', en: 'Services' },
      },
      content: {
        es: `La tecnología puede ser una herramienta muy útil para agilizar y
        optimizar todo, le invitamos a que conozca nuestros servicios.`,
        en: 'Technology can be a very useful tool to streamline and optimize everything, we invite you to learn about our services.',
      },
    },
    customSoftware: {
      title: {
        es: 'Desarrollo de Software a la Medida',
        en: 'Custom Software Development',
      },
      content: {
        es: `Desarrollamos software que se ajusta a las necesidades de su empresa o negocio con tecnología de última gama, ideal para mejorar u optimizar procesos, ya que brindamos soluciones informáticas que se pueden acoplar a la estructura de trabajo existente maximizando sus funcionalidades.`,
        en: 'We develop a software fit to the needs of your organization or business with top technology, ideal to improve and optimize process, by provide an IT solution that can fit together with an existent structure, maximizing its functionalities to a next level.',
      },
      content2: {
        es: `Trabajamos en el desarrollo de nuevas tecnologías, módulos, interfaz gráfica y procesos, acorde a sus requerimientos.`,
        en: 'We work in module base development, technologies, graphic interface, and processes matching your requirements.',
      },
    },
    business: {
      title: { es: 'Inteligencia de Negocios', en: 'BUSINESS INTELLIGENCE' },
      content: {
        es: `Se puede definir Business Intelligence (asociándolo directamente con
          las tecnologías de información) como el conjunto de metodologías,
          aplicaciones y tecnologías que permiten reunir, depurar y transformar
          datos en información estructurada, para su explotación directa, o para
          su análisis y conversión en conocimiento, dando así soporte a la toma
          de decisiones sobre el negocio.`,
        en: 'From the perspective of information technology, Business Intelligence can be defined as the set of methodologies, applications and technologies that allow gathering, purifying and transforming data into structured information, for its direct exploitation, or for its analysis and conversion into knowledge, giving thus support decision-making about the business.',
      },
      content2: {
        es: `Proporcionar información privilegiada para responder a las necesidades
        de negocio: entrada a nuevos mercados, promociones u ofertas de
        productos, eliminación de islas de información, control financiero,
        optimización de costes, planificación de la producción, análisis de
        perfiles de clientes, rentabilidad de un producto concreto, etc.`,
        en: 'We provide privileged information to respond to business needs such as: entry into new markets, promotions or product offers, elimination of islands of information, financial control, cost optimization, production planning, customer profile analysis, product profitability, etc.',
      },
    },
    personnel: {
      title: { es: 'Outsourcing', en: 'Outsourcing' },
      content: {
        es: `Ponemos a disposición equipo de analistas de calidad, gestión de
        proyectos, desarrolladores en la tecnología que requiera como personal
        outsourcing, por el tiempo que lo necesite y de la forma que más le
        convenga. El servicio de outsourcing le permite subcontratar una
        persona capacitada de forma indefinida para integrarse al equipo de
        trabajo del departamento de informática o del proyecto que se realice
        en su empresa. Esto le permite tener personal capacitado únicamente
        cuando lo requiera.`,
        en: 'We make available developers in the technology that you require as personal outsourcing, for as long as you needed. The outsourcing service allows you to subcontract a trained person indefinitely to join the work team of the IT department or the project carried out in your company. This allows you to have trained personnel only when you need them.',
      },
    },
    consultancy: {
      title: { es: 'Consultorías', en: 'Consultancy' },
      content: {
        es: `Ponemos a disposición el servicio de consultorías. Servicio
      profesional prestado por empresas, o por profesionales en forma
      individual conocidas como consultoras o consultores respectivamente
      con experiencia o conocimiento específico en un área, asesorando
      personas, asesorando a otras empresas, a grupos de empresas, a países
      o a organizaciones en general.`,
        en: 'We make the consulting service available. This is a professional service provided by companies, or by individual professionals −known as consultants− with experience or specific knowledge in an area, advising people, advising other companies, groups of companies, countries or organizations in general.',
      },
    },
    trainings: {
      title: { es: 'Capacitaciones', en: 'TRAININGS' },
      content: {
        es: `En la industria de TI, lo más importante para los profesionales es
      empaparse y aprender sobre lo último en tecnologías, tendencias,
      prácticas, etc. Contar con bases sólidas y robustas es indispensable
      para formar el camino a un futuro con éxito. Ponemos a disposición el
      servicio de capacitaciones sobre el uso de tecnologías como Microsoft
      Power BI, Gestión de Proyectos de Software, Introducción al Testing.
      Podemos personalizar el curso como mejor se adapte a su equipo.`,
        en: 'In the IT industry, the most important thing for professionals is to soak up and learn about the latest in technologies, trends, practices, etc. Having solid and robust foundations of ideas is essential to pave the way to a colorful and vivid future. We make available to you the training service on the use of technologies such as Microsoft Power BI, Microsoft Office 365, and the use of work methodologies such as SCRUM.',
      },
    },
    project: {
      title: { es: 'Propuestas de Proyecto', en: 'PROJECT PROPOSALS' },
      content: {
        es: `Es la fase inicial de cualquier proyecto, donde se brinda un panorama
        y concepto amplio del trabajo que se realizará, tomando en cuenta las
        tecnologías que se utilizaran, el tiempo que se empleará, etc. La
        información que se proporciona en una propuesta de proyecto es una
        planificación general del proyecto, detalle de tecnologías a utilizar,
        el costo, descripción de entregables.`,
        en: 'It is the initial phase of any project, where an overview and broad concept of the work to be carried out is provided, taking into account the technologies that will be used, the time that will be used, etc. The information provided in a project proposal is a general planning of the project, detail of technologies to be used, the cost, description of deliverables.',
      },
    },
  },
  client: {
    title: {
      es: 'Nuestros Clientes',
      en: 'our clients',
    },
    disposal: {
      es: 'Estamos a tu disposición',
      en: 'We are at your service',
    },
  },
  contact: {
    title: { es: 'Contáctanos', en: 'CONTACT US' },
    content: {
      es: `Nos interesa conocer tus necesidades, escríbenos y nos pondremos en
    contacto contigo.`,
      en: 'We are interested in knowing your needs, write us and we will contact you.',
    },
    form: {
      name: {
        label: { es: 'Tu nombre *', en: 'Your name *' },
        placeholder: { es: '¿Cómo te llamas?', en: `What's your name?` },
      },
      email: {
        label: { es: 'Tu correo electrónico *', en: 'Your e-mail *' },
        placeholder: { es: '¿Cuál es tu correo electrónico?', en: `What's your email?` },
      },
      phoneNumber: {
        label: { es: 'Tu teléfono *', en: 'Your telephone *' },
        placeholder: {
          es: '¿Cuál es tu número de teléfono?',
          en: `What's your phone number?`,
        },
      },
      employment: {
        label: { es: 'Puesto *', en: 'Position *' },
        placeholder: {
          es: '¿Qué área desempeñas en tu trabajo?',
          en: 'What area do you perform in your job?',
        },
      },
      company: {
        label: { es: 'Compañía *', en: 'Company *' },
        placeholder: {
          es: '¿Cuál es el nombre de la compañía/empresa donde trabajas?',
          en: `What's the name of the company where you work?`,
        },
      },
      message: {
        label: { es: 'Déjanos un mensaje *', en: 'Leave us a message *' },
        placeholder: {
          es: 'Cuéntanos que te gustaría saber',
          en: 'Tell us what you would like to know',
        },
      },
      button: {
        es: 'Enviar',
        en: 'Send',
      },
      msgSucces: {
        es: '¡Gracias! ¡Su propuesta ha sido recibida!',
        en: 'Thanks! Your proposal has been received!'
      }
    },
  },
  lookingJob: {
    title: { es: '¿Buscas empleo?', en: 'Are you looking for a job?' },
    link: { es: 'Quiero unirme al equipo', en: 'I want to join the team' },
  },
  applyWithUs: {
    main: {
      title: { es: 'APLICA CON NOSOTROS', en: 'APPLY WITH US' },
      content: {
        es: `Este es un espacio para que puedas escribirnos y contarnos acerca de ti, será un
      gusto que formes parte de nuestro equipo`,
        en: 'This is a space where you can write to us and tell us about yourself. It will be a pleasure for us to have you as part of our team.',
      },
    },
    look: {
      title: { es: 'Buscamos nuevos talentos', en: 'WE ARE LOOKING FOR NEW TALENTS' },
      content: {
        es: `En ITProfis confiamos que desarrollaras todo tu potencial, trabajando en
      proyectos de alto impacto, para empresas nacionales e internacionales.`,
        en: 'IT Profis trust that you will develop your full potential, working on high-impact projects for national and international companies.',
      },
      content1: {
        es: '¡Compártenos tu hoja de vida para que puedas aplicar con nosotros!',
        en: 'Share your resume so you can apply with us!',
      },
    },
    location: { es: 'Ubicación en', en: 'Location in' },
    formTitle: { es: 'Datos personales', en: 'Personal information' },
    form: {
      fullName: {
        label: { es: 'Nombre completo *', en: 'Full name *' },
        placeholder: { es: 'Nombre completo', en: `What's your name?` },
      },
      email: {
        label: { es: 'Correo electrónico *', en: 'Email *' },
        placeholder: { es: 'Correo electrónico', en: `What's your email?` },
      },
      country: {
        label: { es: 'País *', en: 'Country *' },
        placeholder: { es: 'País', en: `Country` },
      },
      phoneNumber: {
        label: { es: 'Número de teléfono *', en: 'Phone number *' },
        placeholder: { es: 'Número de teléfono', en: `Phone number` },
      },
      address: {
        label: { es: 'Dirección', en: 'Address *' },
        placeholder: { es: 'Dirección exacta', en: `Exact direction` },
      },
      salary: {
        label: { es: 'Pretención Salarial *', en: 'Wage Pretension *' },
        placeholder: { es: 'Pretensión salarial (en', en: `Salary claim (in` },
      },
      skill: {
        label: { es: 'Experiencia *', en: 'Experience *' },
        placeholder: {
          es: 'Escribe un resumen de tu experiencia',
          en: `Write a summary of your experience`,
        },
      },
      content: {
        label: { es: 'Currículum *', en: 'Resume *' },
        placeholder: { es: '', en: `` },
      },
      button: {
        es: 'Enviar',
        en: 'Send',
      },
      msgSucces: {
        es: 'Tu información se ha enviado correctamente. Pronto nos pondremos en contacto contigo',
        en: 'Your information has been sent correctly. We will contact you soon',
      },
    },
  },
  footer: {
    es: '© 2022 IT Profis S.A. Derechos reservados.',
    en: '© 2022 IT Profis SA All rights reserved.'
  },
  operation: {
    es: 'Operaciones',
    en: 'Operations'
  }, 
  sales: {
    es: 'Ventas',
    en: 'Sales'
  },

  qr: {
    es: 'Inicie conversación escaneando el código QR',
    en: "Start conversation by scanning the QR code"
  }
};
