import { useEffect, useRef } from 'react';
import { moveScroll } from '../../helpers/scroll';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { useAppSelector } from '../../store/hook';
import { languajes } from '../../helpers';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
const { home } = languajes;
const { 
  main, 
  whoAreSection, 
  valueSection, 
  mapsSection, 
  sloganSection,
  banner,
} = home;

gsap.registerPlugin(ScrollToPlugin, ScrollTrigger);
export const HomeSection = () => {
  const ref = useRef<HTMLHeadingElement>(null);

  const { isES } = useAppSelector((state) => state.languaje);

  useEffect(() => {
    if (window.innerWidth > 991) {
      const element = ref.current;
      const panelsContainer = element?.querySelector('.panels-container') as HTMLElement;
      const panels = gsap.utils.toArray('.horizontal-item');

      let scroll_tl = gsap.timeline({
          scrollTrigger: {
            trigger: '.factsContainer',
            start: 'top center',
            scrub: true,
            end: '+=300',
          },
        }),
        facts = document.querySelectorAll('.fact');
      scroll_tl.to('.factsContainer h2', {
        scale: 1,
        duration: 1,
        ease: 'slow',
      });

      gsap.to(panels, {
        xPercent: -100 * (panels.length - 1),
        ease: 'none',
        scrollTrigger: {
          trigger: '.panels-container',
          pin: true,
          scrub: 1,
          snap: 1 / (panels.length - 1),
          start: 'top top',
          end: () => '+=' + panelsContainer.offsetWidth / 1,
        },
      });
    }
  }, []);

  return (
    <div ref={ref}>
      {/* =========== PANEL 0: SOFTWARE E INTELIGENCIA ==========================*/}
      <section id="hero-section" className="wf-section">
        <div className="hero-section w-row">
          <div className="title-hero-column w-col w-col-4 w-col-stack">
            <img
              src="images/white_IT-Profis-logo.svg"
              loading="lazy"
              width="270"
              alt=""
              className="hero-it-profis-logo"
            />
            <h1 className="hero-title">
              <span className="hero-span">Software</span>
              <span className="hero-span">{isES ? main.and.es : main.and.en}</span>

              <span className="hero-span">
                {isES ? main.bussiness.es : main.bussiness.en}
              </span>
            </h1>
            <div className="hero-div">
              <div className="hero-line-deco"></div>
              {/* <a href="#que-es-it-profis" className="link">
                Conocer más
              </a> */}
              <button className="btn-link" onClick={() => moveScroll('que-es-it-profis')}>
                {isES ? main.learn.es : main.learn.en}
              </button>
            </div>
            <div className="hero-social-media">
              <a
                href="https://www.facebook.com/ITProfis.gt"
                target="_blank"
                className="social-media-link w-inline-block"
                rel="noopener noreferrer"
              >
                <img
                  src="images/white_facebook.svg"
                  loading="lazy"
                  width="24"
                  height="24"
                  alt=""
                />
              </a>
              <a
                href="https://www.linkedin.com/company/itprofis-gt/"
                target="_blank"
                className="social-media-link w-inline-block"
                rel="noopener noreferrer"
              >
                <img
                  src="images/linkedin.svg"
                  loading="lazy"
                  width="24"
                  height="24"
                  alt=""
                />
              </a>
              <a
                href="https://wa.me/message/4GQQN2FDCLZVE1"
                target="_blank"
                className="social-media-link w-inline-block"
                rel="noopener noreferrer"
              >
              <WhatsAppIcon sx={{ fontSize: 30,color:"#fff" }}  />
              </a>
            </div>
          </div>
          <div className="info-column-div w-col w-col-8 w-col-stack">
            <div className="hero-info-box software">
              <div className="hero-info-container-software">
                <h2 className="hero-info-title">
                  {isES ? main.software.title.es : main.software.title.en}
                </h2>
                <p className="hero-info-pharagraph text-align">
                  {isES ? main.software.content.es : main.software.content.en}
                </p>
              </div>
            </div>
            <div className="hero-info-box outsourcing">
              <div className="hero-info-container-outsourcing">
                <h2 className="hero-info-title">
                  {isES ? main.outsourcing.title.es : main.outsourcing.title.en}
                </h2>
                <p className="hero-info-pharagraph text-align">
                  {isES ? main.outsourcing.content.es : main.outsourcing.content.en}
                </p>
              </div>
            </div>
            <div className="hero-info-box inteligence">
              <div className="hero-info-container-inteligence">
                <h2 className="hero-info-title">
                  {isES ? main.intelligence.title.es : main.intelligence.title.en}
                </h2>
                <p className="hero-info-pharagraph text-align">
                  {isES ? main.intelligence.content.es : main.intelligence.content.en}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="wrapper camera">
        <div className="factsContainer">
          <div
            className="factsContainer_sm frame panels-container"
            style={{ width: '100%' }}
          >
            {/* =========== PANEL 1: QUIENES SOMOS ==========================*/}
            <section id="que-es-it-profis" className="fact horizontal-item">
              <div className="info-columns w-row">
                <div className="column w-col w-col-5 w-col-stack">
                  <div className="div-text bottom">
                    <h2 className="title-section">
                      {isES ? whoAreSection.title.who.es : whoAreSection.title.who.en}
                    </h2>
                    <div>
                      <p className="paragraph">
                        {isES
                          ? whoAreSection.content.paragraph1.es
                          : whoAreSection.content.paragraph1.en
                        }
                      </p>
                      <p className="paragraph">
                        {isES
                          ? whoAreSection.content.paragraph2.es
                          : whoAreSection.content.paragraph2.en
                        }
                      </p>
                      <p className="paragraph">
                        {isES
                          ? whoAreSection.content.paragraph3.es
                          : whoAreSection.content.paragraph3.en
                        }
                      </p>
                    </div>
                  </div>
                </div>
                <div className="column w-col w-col-4 w-col-stack">
                  <img
                    src="images/about-it-profis-min.jpg"
                    loading="lazy"
                    sizes="(max-width: 479px) 100vw, (max-width: 767px) 92vw, (max-width: 991px) 90vw, 30vw"
                    srcSet="images/about-it-profis-min-p-500.jpg 500w, images/about-it-profis-min-p-800.jpg 800w, images/about-it-profis-min-p-1080.jpg 1080w, images/about-it-profis-min-p-1600.jpg 1600w, images/about-it-profis-min-p-2000.jpg 2000w, images/about-it-profis-min-p-2600.jpg 2600w"
                    alt=""
                    className="imagen"
                  />
                </div>
                <div className="column w-col w-col-3 w-col-stack">
                  <div className="div-gallery">
                    <img
                      src="images/about-it-profis_03-min.jpg"
                      loading="lazy"
                      width="235"
                      id="w-node-_7a159f58-5b6b-cd36-2bea-7a1d821c3458-367a1fe4"
                      srcSet="images/about-it-profis_03-min-p-500.jpg 500w, images/about-it-profis_03-min-p-800.jpg 800w, images/about-it-profis_03-min-p-1080.jpg 1080w, images/about-it-profis_03-min-p-1600.jpg 1600w, images/about-it-profis_03-min-p-2000.jpg 2000w, images/about-it-profis_03-min-p-2600.jpg 2600w, images/about-it-profis_03-min-p-3200.jpg 3200w"
                      sizes="(max-width: 479px) 0px, (max-width: 991px) 100vw, 30vw"
                      alt=""
                      className="image-2"
                    />
                    <img
                      src="images/about-it-profis_02-min.jpg"
                      loading="lazy"
                      id="w-node-_7440dcba-9d48-18fc-d9a4-eb3af29eb3d6-367a1fe4"
                      sizes="(max-width: 479px) 0px, (max-width: 991px) 100vw, 30vw"
                      srcSet="images/about-it-profis_02-min-p-500.jpg 500w, images/about-it-profis_02-min-p-800.jpg 800w, images/about-it-profis_02-min-p-1080.jpg 1080w"
                      alt=""
                      className="image-2"
                    />
                  </div>
                </div>
                <div className="arrows">
                  <span className="h-scroll w-inline-block"><img src="" loading="lazy" alt="" /></span>
                  <span className="h-scroll w-inline-block"><img src="images/white_rightchevron.svg" loading="lazy" alt="" width="40px" /></span>
                </div>
              </div>
            </section>
            {/* =========== PANEL 2: PROPUESTA DE VALOR ==========================*/}
            <div className="fact horizontal-item white-bg">
              <div className="info-columns w-row">
                <div className="column w-col w-col-4 w-col-stack">
                  <div className="div-text bottom">
                    <h2 className="title-section white-bg">
                      {isES 
                        ? valueSection.title.proposition.es 
                        : valueSection.title.proposition.en
                      }
                      <br />
                      {isES 
                        ? valueSection.title.value.es 
                        : valueSection.title.value.en
                      }
                    </h2>
                    <div>
                      <p className="paragraph">
                      {isES 
                        ? valueSection.content.main.es
                        : valueSection.content.main.en
                      }
                      </p>
                    </div>
                  </div>
                </div>
                <div className="column w-col w-col-4 w-col-stack">
                  <div className="div-text bottom">
                    <div>
                      <h2 className="hero-info-title">
                        {isES 
                          ? valueSection.content.ourVision.title.es
                          : valueSection.content.ourVision.title.en
                        }
                      </h2>
                      <p className="paragraph">
                        {isES 
                          ? valueSection.content.ourVision.content.es
                          : valueSection.content.ourVision.content.en
                        }
                      </p>
                      <h2 className="hero-info-title">
                        {isES 
                          ? valueSection.content.ourMision.title.es
                          : valueSection.content.ourMision.title.en
                        }
                      </h2>
                      <p className="paragraph">
                        {isES 
                          ? valueSection.content.ourMision.content.es
                          : valueSection.content.ourMision.content.en
                        }
                      </p>
                    </div>
                  </div>
                </div>
                <div className="column w-col w-col-4 w-col-stack">
                  <img
                    src="images/our-value-min.jpg"
                    loading="lazy"
                    sizes="(max-width: 479px) 100vw, (max-width: 767px) 92vw, (max-width: 991px) 90vw, 30vw"
                    srcSet="images/our-value-min-p-500.jpg 500w, images/our-value-min-p-800.jpg 800w, images/our-value-min-p-1080.jpg 1080w, images/our-value-min-p-1600.jpg 1600w, images/our-value-min-p-2000.jpg 2000w, images/our-value-min-p-2600.jpg 2600w"
                    alt=""
                    className="imagen"
                  />
                </div>
              </div>
              <div className="arrows">
                <span className="h-scroll w-inline-block"><img src="images/dark_leftchevron.svg" loading="lazy" alt="" width="40px" /></span>
                <span className="h-scroll w-inline-block"><img src="images/dark_rightchevron.svg" loading="lazy" alt="" width="40px" /></span>
              </div>
            </div>
            {/* =========== PANEL 3: MAPAS ==========================*/}
            <div className="fact horizontal-item">
              <div className="info-columns region w-row">
                <div className="column w-col w-col-8 w-col-stack">
                  <div className="div-text" >
                    <img
                      src="images/america-map.svg"
                      loading="lazy"
                      style={{maxWidth:"70%"}}
                      sizes="(max-width: 479px) 100vw, (max-width: 767px) 92vw, (max-width: 991px) 90vw, 30vw"
                      srcSet="images/america-map.svg 500w , images/america-map.svg 800w , images/america-map.svg 1080w , images/america-map.svg 1472w "
                      alt=""
                    />
                  </div>
                </div>
                <div className="column w-col w-col-4 w-col-stack">
                  <div className="div-text">
                    <h2 className="title-section">
                      {isES ? mapsSection.title.our.es : mapsSection.title.our.en}
                      <br />
                      {isES ? mapsSection.title.scope.es : mapsSection.title.scope.en}
                    </h2>
                    <div></div>
                  </div>
                </div>
              </div>
              <div className="arrows">
                <span className="h-scroll w-inline-block"><img src="images/white_leftchevron.svg" loading="lazy" alt="" width="40px" /></span>
                <span className="h-scroll w-inline-block"><img src="images/white_rightchevron.svg" loading="lazy" alt="" width="40px" /></span>
              </div>
            </div>
            {/* =========== PANEL 4: it profis ==========================*/}
            <div className="fact horizontal-item white-bg" >
              <div className="logo-presentation info-columns">
                <img src="images/IT-Profis-logo.svg" loading="lazy" width="570" alt="" />
                <h3 className="tagline">
                  {isES ? sloganSection.software.es : sloganSection.software.en}
                  <br />
                  {isES ? sloganSection.business.es : sloganSection.business.en}
                </h3>
              </div>
              <div className="arrows">
                <span className="h-scroll w-inline-block"><img src="images/dark_leftchevron.svg" loading="lazy" alt="" width="40px" /></span>
              
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="banners">
        <div className="container-max w-container">
          <h2 className="banner-text">{isES ? banner.es : banner.en}</h2>
        </div>
      </div>
    </div>
  );
};
