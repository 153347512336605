import { useAppSelector } from "../../store/hook";
import { languajes } from '../../helpers/languajes';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
const { footer } = languajes;
export const Footer = () => {
  const { isES } = useAppSelector((state) => state.languaje);
  return (
    <div className="footer">
      <div className="container-max flex-container w-container">
        <div className="copyright">
          <div>{isES ? footer.es : footer.en}</div>
        </div>
        <div className="footer-social-media">
          <a
            href="https://www.facebook.com/ITProfis.gt"
            target="_blank"
            className="social-media-link w-inline-block"
            rel="noopener noreferrer"
          >
            <img
              src="images/white_facebook.svg"
              loading="lazy"
              width={24}
              height={24}
              alt=""
            />
          </a>
          <a
            href="https://www.linkedin.com/company/itprofis-gt/"
            target="_blank"
            className="social-media-link w-inline-block"
            rel="noopener noreferrer"
          >
            <img src="images/linkedin.svg" loading="lazy" width={24} height={24} alt="" />
          </a>
          <a
                href="https://wa.me/message/4GQQN2FDCLZVE1"
                target="_blank"
                className="social-media-link w-inline-block"
                rel="noopener noreferrer"
              >
              <WhatsAppIcon sx={{ fontSize: 30,color:"#fff" }}  />
              </a>
        </div>
      </div>
    </div>
  );
};
