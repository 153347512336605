import * as Yup from 'yup';

export const validateSchemaFormJobES = Yup.object({
  address    : Yup.string().required('Requerido'),
  country    : Yup.string().required('Requerido'),
  email      : Yup.string().email('Correo electrónico inválido').required('Requerido'),
  fullName   : Yup.string()
               .matches(/^[a-zA-Z\u00C0-\u017F\s]+$/, 'Solo son permitidos letras')
               .required('Requerido'),
  phoneNumber: Yup.string()
               .matches(
                  /^\(?(\+[0-9]{3})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/, 
                  'Número de teléfono inválido'
                )
               .required('Requerido'),
  salary     : Yup.number()
               .positive('Debe ser un valor numérico positivo')
               .required('Requerido'),
  skill      : Yup.string()
               .min(5, 'Debe tener 5 caracteres o más')
               .required('Requerido'),
});

export const validateSchemaFormJobEN = Yup.object({
  address    : Yup.string().required('Required'),
  country    : Yup.string().required('Required'),
  email      : Yup.string().email('Invalid Email').required('Required'),
  fullName   : Yup.string()
               .matches(/^[a-zA-Z\u00C0-\u017F\s]+$/, 'Only letters are allowed')
               .required('Required'),
  phoneNumber: Yup.string()
               .matches(
                  /^\(?(\+[0-9]{3})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/, 
                  'Invalid phone number'
                )
               .required('Required'),
  salary     : Yup.number()
               .positive('Must be a positive numeric value')
               .required('Required'),
  skill      : Yup.string()
               .min(5, 'Must be 5 characters or more')
               .required('Required'),
});


export const validateSchemaContactES = Yup.object({
  company    : Yup.string().required('Requerido'),
  message    : Yup.string().required('Requerido'),
  employment : Yup.string().required('Requerido'),
  name       : Yup.string()
               .matches(/^[a-zA-Z\u00C0-\u017F\s]+$/, 'Solo son permitidos letras')
               .required('Requerido'),
  email      : Yup.string().email('Correo electrónico inválido').required('Requerido'),
  phoneNumber: Yup.string()
               .max(30, 'Debe de tener 30 caracteres o menos')
               .required('Requerido'),
});

export const validateSchemaContactEN = Yup.object({
  company    : Yup.string().required('Required'),
  message    : Yup.string().required('Required'),
  employment : Yup.string().required('Required'),
  name       : Yup.string()
               .matches(/^[a-zA-Z\u00C0-\u017F\s]+$/, 'Only letters are allowed')
               .required('Required'),
  email      : Yup.string().email('Invalid Email').required('Required'),
  phoneNumber: Yup.string()
               .max(30, 'Must be 30 characters or less')
               .required('Required'),
});