import {createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
// import { RootState } from '../../store';

export interface AuthState {
  token: string;
  expires: string;
};

const initialState: AuthState = {
  token  : '',
  expires: '',
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setToken: ( state, action: PayloadAction<AuthState> ) => {
      state.token = action.payload.token;
      state.expires = action.payload.expires;
    }
  }
});



/*
export const startAuthenticate = (): ThunkAction<void, RootState, unknown, AnyAction> => 
  async (dispatch, getState ) => {
    const { token } = getState().auth;
    dispatch(setToke())
}
*/

export const { setToken } = authSlice.actions;

export default authSlice.reducer;

