import { configureStore } from '@reduxjs/toolkit';
import authSliceReducer from './slices/auth/authSlice';
import languajeReducer from './slices/languaje/languajeSlice';
import uiReducer from './slices/ui/uiSlice';

export const store = configureStore({
  reducer: {
    auth    : authSliceReducer,
    languaje: languajeReducer,
    ui: uiReducer
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
