import axios from 'axios';

const baseURL = `${process.env.REACT_APP_API_ROOT}`;

export const itProfisAPIAuth = ( token: string ) =>  axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
    "Access-Control-Allow-Origin": '*',
  }
});

export const itProfisAPI = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
    "Access-Control-Allow-Origin": '*',
  }
});