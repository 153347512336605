import { useEffect } from 'react';

import { Formik, Form } from 'formik';

import { TextInput, TextArea } from '..';
import { useFormContact } from '../../hooks';
import { validateSchemaContactEN, validateSchemaContactES } from '../../helpers'
import { SpinnerBttn } from '../../animation';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';

// import ReCAPTCHA from 'react-google-recaptcha';

export const FormContact = () => {

  const {
    initialValues,
    isLoading,
    showSuccess,
    isES,
    refreshReCaptcha,
    respError,

    onSubmit,
    setTimeOutSucces,
    getLabelByLanguaje,
    getPlaceholderByLanguaje,
    getTextAux,
    onVerify,
    setTimeOutError
  } = useFormContact();

  useEffect(() => {
    setTimeOutSucces();
    // eslint-disable-next-line 
  }, [showSuccess]);

  useEffect(() => {
    setTimeOutError();
    // eslint-disable-next-line 
  }, [respError.error])
  
  
  return (
    <div className="contact-form w-form">
      <Formik 
        initialValues={initialValues} 
        onSubmit={async (values, actions) => await onSubmit(values, actions)}
        validationSchema={isES ? validateSchemaContactES : validateSchemaContactEN }
      >
        {() => (
          <Form noValidate>
            <TextInput 
              name="name"
              type='text'
              className="contact-field name w-input"
              placeholder={getPlaceholderByLanguaje('name')}
              label={getLabelByLanguaje('name')}
            />
            <TextInput 
              name="email"
              type='email'
              className="contact-field email w-input"
              placeholder={getPlaceholderByLanguaje('email')}
              label={getLabelByLanguaje('email')}
            />
            <TextInput 
              name="phoneNumber"
              type="text"
              className="contact-field phone w-input"
              placeholder={getPlaceholderByLanguaje('phoneNumber')}
              label={getLabelByLanguaje('phoneNumber')}
            />
            <TextInput 
              name="employment"
              type="text"
              className="contact-field office w-input"
              placeholder={getPlaceholderByLanguaje('employment')}
              label={getLabelByLanguaje('employment')}
            />
            <TextInput 
              name="company"
              type="text"
              className="contact-field company w-input"
              placeholder={getPlaceholderByLanguaje('company')}
              label={getLabelByLanguaje('company')}
            />

            <TextArea
              name="message"
              className="contact-field message w-input"
              placeholder={getPlaceholderByLanguaje('message')}
              label={getLabelByLanguaje('message')}
            />

            <div 
              style={{ 
                width: '100%', 
                marginBottom: '10px', 
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {/* <ReCAPTCHA
                sitekey={keyCaptcha}
                onChange={onChange}
                ref={captcha}
              /> */}
              <GoogleReCaptcha
                onVerify={onVerify}
                refreshReCaptcha={refreshReCaptcha}
              />
            </div>
            {!showSuccess && (
              <button 
                className="button w-inline-block" 
                style={{ width: '100%' }} 
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? <SpinnerBttn isES={isES} /> : getTextAux('bttn')}
                {!isLoading && (
                  <img 
                    src="images/white_right-arrow.svg" 
                    loading="lazy" 
                    width={24} 
                    alt="" 
                  />
                )}
              </button>
            )}
          </Form>
        )}
        
      </Formik>
      {showSuccess && (
        <div className="w-form-done" style={{ marginTop: '10px' }}>
          <div>{getTextAux('msgSucces')}</div>
        </div>
      )}
      {respError.error && (
         <div className="w-form-fail" style={{ marginTop: '10px', textAlign: 'center' }}>
          <div>{isES ? respError.msg.es : respError.msg.en}</div>
        </div>
        )}
    </div>
  );
};

// 11A388