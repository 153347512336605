import { useEffect } from 'react';
import { Navigate, Route } from 'react-router';
import { Routes,HashRouter } from 'react-router-dom';
import { DashbordView, JobView } from '../views';
import { Navbar, Footer } from '../components';
import { useAppDispatch, useAppSelector } from '../store/hook';
import { authenticate } from '../api/authApi';
import { Spinner } from '../animation';

export const DashboardRoutes = () => {

  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector( state => state.ui );

  useEffect(() => {
    
    dispatch(authenticate());

  }, [dispatch])
  
  if (isLoading) return <Spinner />

  return (
    <div className="body">
      <Navbar />
      <Routes>
        <Route path="/" index element={<DashbordView />} />
        <Route path="/ES"  element={<DashbordView />} />
        <Route path="/EN"  element={<DashbordView />} />
        <Route path="/es"  element={<DashbordView />} />
        <Route path="/en"  element={<DashbordView />} />
        <Route path="/join-team"  element={<JobView />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      <Footer />
    </div>
  );
};
