import { useState, ChangeEvent, useCallback } from 'react';

import { FormikHelpers } from 'formik';
import { 
  JobForm, 
  CountryType, 
  SetFielValue,
  ChangeSelect 
} from '../interfaces';

import { getToBase64, getBytesToMB, sleep, getSubstractDate } from '../helpers';
import { validateCaptchaToken } from '../api';
import { useAppDispatch, useAppSelector } from '../store/hook';
import { languajes } from '../helpers/languajes';

// import ReCAPTCHA from 'react-google-recaptcha';
import { sendJobEmail } from '../api/mailApi';
import { authenticate } from '../api/authApi';

type HelperFomik = FormikHelpers<JobForm>;
type Event = ChangeEvent<HTMLInputElement>;

type KeyForm = 
'address' |
'country' |
'email' |
'fullName' |
'phoneNumber' |
'salary' |
'skill' |
'content';

const initialValues: JobForm = {
  address    : '',
  country    : 'Guatemala',
  email      : '',
  fullName   : '',
  phoneNumber: '+502 ',
  salary     : '',
  skill      : '',
  content    : undefined, // curriculum
};

const countries: CountryType[] = [
  { id: 1, value: 'Guatemala', text: 'Guatemala', prefixNum: '+502', classStyle: 'gt-flag', classStyleOption: 'gt'  },
  { id: 2, value: 'El_Salvador', text: 'El Salvador', prefixNum: '+503', classStyle: 'salvador-flag', classStyleOption: 'salvador' }
];


const initialValuesError = { error: false, msg: { es: '', en: ''} };


const keyCaptcha = `${process.env.REACT_APP_reCAPTCHA}`;

const { applyWithUs } = languajes;
const { form } = applyWithUs;
export const useFormJob = () => {

  const [respError, setRespError] = useState(initialValuesError);
  const [showSuccess, setShowSuccess] = useState(false);
  const [fileError, setFileError] = useState(initialValuesError);
  const [isLoading, setIsLoading] = useState(false);
  const [token, setToken] = useState<string>('');
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);

  const { isES } = useAppSelector((state) => state.languaje);
  const { token: tokenAuth, expires } = useAppSelector( state => state.auth );

  const dispatch = useAppDispatch();

  const setTimeOutSucces = async () => {
    if ( showSuccess ) {
      await sleep(7);
      setShowSuccess(false)
    } 
  }

  const setTimeOutError = async () => {
    if ( respError.error ) {
      await sleep(5);
      setRespError(initialValuesError);
    } 
  }

  const onVerify = useCallback((tokenCaptcha: string) => {
    setToken(tokenCaptcha);
  }, []);

  const getLabelByKey = useCallback(( key: KeyForm ) => {
    return isES ? form[key].label.es : form[key].label.en;
  }, [isES]);

  const getPlaceholderByKey = useCallback(( key: KeyForm ) => {
    return isES ? form[key].placeholder.es : form[key].placeholder.en;
  }, [isES]);

  const getTextAux = useCallback(( type: 'bttn' | 'msgSucces' ) => {

    if ( type === 'bttn' )
      return isES ? form.button.es : form.button.en;

    return isES ? form.msgSucces.es : form.msgSucces.en;

  }, [isES]);

  const onSubmit = async ( values: JobForm, actions: HelperFomik  ) => { 

    if ( fileError.error ) return;

    if ( !values.content || values.content === '' ) {
      setFileError({ error: true, msg: { es: 'Requerido', en: 'Required'} });
      return;
    }


    setIsLoading(true);

    await renewSession();
  
    const resp = await validateCaptchaToken(tokenAuth, token);

    if ( !resp.succes ) {
      setIsLoading(false);
      setRespError({ error: true, msg: { es: resp.msgEs, en: resp.msgEn } });
      return;
    }

    if ( resp.score < 0.8 ) {
      setIsLoading(false);
      setRespError({ error: true, msg: { es: 'Captcha invalido', en: 'Invalid Captcha' } });
      return;
    }

    const valuesForm = {
      ...values,
      content: await getToBase64(values.content),
    }

    const respEmail = await sendJobEmail(valuesForm, tokenAuth);

    if ( !respEmail.succes ) {
      setIsLoading(false);
      setRespError({ error: true, msg: { es: respEmail.msgEs, en: respEmail.msgEn } });
      return;
    }


    setRespError(initialValuesError);
    actions.resetForm();
    setIsLoading(false);
    setRefreshReCaptcha( r => !r );

    setShowSuccess(true);
    return;

  }

  const renewSession = async () => {
    if ( getSubstractDate(expires) ) await dispatch(authenticate(false));
  }

  const onChangeInputFile = ( e: Event, setFieldValue: SetFielValue ) => {
    const file = e.target.files ? e.target.files[0] : undefined
    
    if ( !file ) {
      setFileError({ error: true, msg: { es: 'Requerido', en: 'Required'} });
      return;
    }

    if ( file.type !== 'application/pdf' ) {
      // setFileError({ error: true, msg: 'El currículum no tiene formato PDF' });
      setFileError({ error: true, msg: { es: 'El currículum no tiene formato PDF', en: 'The resume is not in PDF format' } });
      return;
    }

    if ( getBytesToMB(file.size) > 2 ) {
      // setFileError({ error: true, msg: 'El tamaño máximo del currículum debe ser de 2 MB' });
      setFileError({ error: true, msg: { es: 'El tamaño máximo del currículum debe ser de 2 MB', en: 'The maximum size of the resume should be 2 MB' } });
      return;
    }

    setFileError(initialValuesError);
    setFieldValue('content', file);
  }

  const onChangeSelect = ( { inputName, inputValue, setFieldValue}: ChangeSelect ) => {

    setFieldValue(inputName, inputValue, true);
    const prefix = countries.find( country => country.value === inputValue )

    setFieldValue('phoneNumber', `${prefix?.prefixNum || ''} `);

  }

  const getFlagByCountry = ( value: string ) => {
    const countrySelect = countries.find( country => country.value === value );
    return countrySelect ? countrySelect.classStyle : '';
  }

  return {
    countries,
    fileError,
    initialValues,
    isLoading,
    isES,
    keyCaptcha,
    showSuccess,
    refreshReCaptcha,
    respError,

    onChangeInputFile,
    onChangeSelect,
    onSubmit,
    setTimeOutSucces,
    setTimeOutError,
    onVerify,
    getLabelByKey,
    getPlaceholderByKey,
    getTextAux,
    getFlagByCountry,
  };
}