import { FC, MouseEventHandler } from 'react';
import Slider, { CustomArrowProps } from 'react-slick';
import { useAppSelector } from '../../store/hook';
import { languajes } from '../../helpers/languajes';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const { client } = languajes;
export const ClientsSection = () => {
  const settings = {
    dots          : false,
    infinite      : true,
    speed         : 650,
    slidesToShow  : 1,
    slidesToScroll: 1,
    initialSlide  : 0,
    prevArrow     : <PrevArrow />,
    nextArrow     : <NextArrow />,
    autoplay      : true,
    autoplaySpeed : 3000,
    pauseOnHover  : true,
    customPaging  : ( i: number ) => <CustomDot index={i} />,
  };

  const { isES } = useAppSelector((state) => state.languaje);

  return (
    <>
      <div className="our-clients">
        <div className="container-max w-container">
          <h2 className="title-section">
            {isES ? client.title.es : client.title.en}
          </h2>
          <div
            className="slider w-slider"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-start',
            }}
          >
            <Slider {...settings} className="mask w-slider-mask">
              {/*  =============== SECCION 1 ============ */}
              <div className="slide w-slide">
                <div className="clients-logos">
                  <img
                    src="images/canella_whitelogo2.png"
                    loading="lazy"
                    alt=""
                    className="client-logo"
                  />
                  <img
                    src="images/Airpak_Logo.png"
                    loading="lazy"
                    alt=""
                    className="client-logo"
                  />
                  <img
                    src="images/Alcsa_Logo.png"
                    loading="lazy"
                    alt=""
                    className="client-logo"
                  />
                  <img
                    src="images/MAPFRE_Logo.png"
                    loading="lazy"
                    alt=""
                    className="client-logo"
                  />
                  <img
                    src="images/SARLatam_Logo.png"
                    loading="lazy"
                    alt=""
                    className="client-logo"
                  />
                </div>
              </div>
              {/*  =============== SECCION 2 ============ */}
              <div className="slide w-slide">
                <div className="clients-logos">
                  <img
                    src="images/TransUnion_Logo.png"
                    loading="lazy"
                    alt=""
                    className="client-logo"
                  />
                  <img
                    src="images/RedEfectiva_Logo.png"
                    loading="lazy"
                    alt=""
                    className="client-logo"
                  />
                  <img
                    src="images/QuetzalAsistencia_Logo.png"
                    loading="lazy"
                    alt=""
                    className="client-logo"
                  />
                  <img
                    src="images/IngenioLaUnion_Logo.png"
                    loading="lazy"
                    alt=""
                    className="client-logo"
                  />
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>
      {/* =================== BANNERS 02 ============== */}
      <div className="banners _02">
        <div className="container-max w-container">
          <h2 className="banner-text">
            {isES ? client.disposal.es : client.disposal.en}
          </h2>
        </div>
      </div>
    </>
  );
};

const PrevArrow: FC<CustomArrowProps> = ({ onClick }) => {
  return (
    <div className="left-arrow w-slider-arrow-left" onClick={onClick}>
      <div className="w-icon-slider-left" />
    </div>
  );
};

const NextArrow: FC<CustomArrowProps> = ({ onClick }) => {
  return (
    <div className="right-arrow w-slider-arrow-right" onClick={onClick}>
      <div className="w-icon-slider-right" />
    </div>
  );
};

interface CustomDotProps {
  onClick?: MouseEventHandler<any>;
  index: number;
}

const CustomDot: FC<CustomDotProps> = ({ onClick, index }) => {
  return (
    <div
      className="w-slider-dot"
      data-wf-ignore
      aria-pressed="true"
      role="button"
      tabIndex={0}
      style={{ marginLeft: '5px', marginRight: '5px', borderRadius: '100%' }}
      onClick={onClick}
      key={index}
    />
  );
};
