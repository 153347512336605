import { useAppSelector } from "../../store/hook";
import { languajes } from '../../helpers/languajes';

const { applyWithUs } = languajes;
const { main } = applyWithUs;

export const ApplyJob = () => {

  const { isES } = useAppSelector((state) => state.languaje);

  return (
    <div className="join-team-header wf-section">
      <div className="container-max w-container">
        <h2 className="title-section white-bg">{isES ?  main.title.es : main.title.en }</h2>
        <p className="join-team-header-pharagraph">
          {isES ?  main.content.es : main.content.en }
        </p>
      </div>
    </div>
  );
};
