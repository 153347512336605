import {createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
// import { RootState } from '../../store';

export interface UIState {
  isLoading: boolean;
};

const initialState: UIState = {
  isLoading: false
};

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setLoading: ( state, action: PayloadAction<boolean> ) => {
      state.isLoading = action.payload
    }
  }
});


export const { setLoading } = uiSlice.actions;

export default uiSlice.reducer;

