import { FC, useEffect, useState } from 'react';
import { Link, scroller } from 'react-scroll';
import { NavLink, useLocation, useNavigate, Location } from 'react-router-dom';
import { sleep, languajes, moveScroll } from '../../helpers';

import { useAppDispatch, useAppSelector } from '../../store/hook';
import { setLanguaje } from '../../store/slices/languaje/languajeSlice';

const { navbar } = languajes;

export const Navbar = () => {

  const [isOpenLanguaje, setIsOpenLanguaje] = useState(false);
  const [isOpenNavResponsive, setIsOpenNavResponsive] = useState(false);
  const [currentLink, setCurrentLink] = useState('hero-section');

  const { isES } = useAppSelector( state => state.languaje );
  
  const location = useLocation();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  useEffect(()=>{

    if(location.pathname!="/ES" && location.pathname != "/"  && location.pathname != "/es"  ){
      changeLanguaje(false);
    }
  },[])
  const navigateWithScroll = async ( 
    link: string, 
    section: string, 
    isActiveResponsive: boolean = false,
    offset: number = -100
  ) => {
    navigate(link);
    await sleep(0.2);
    scroller.scrollTo(section, {
      duration : 500,
      smooth: true,
      offset,
      spy: true,
    })

    isActiveResponsive && setIsOpenNavResponsive(false)
  }

  const changeLanguaje = ( isSpanish: boolean ) => { 
    dispatch(setLanguaje(isSpanish));
  }


  return (
    <div
      data-animation="default"
      data-collapse="small"
      data-duration="400"
      data-easing="ease-out"
      data-easing2="ease-in"
      data-doc-height="1"
      role="banner"
      className="w-nav navbar"
    >
      <div className="nav-container w-container">
        <div className="nav-brand-div">
          <NavLink to="/" className="nav-brand w-nav-brand w--current">
            <img src="/images/IT-Profis-logo.svg" loading="lazy" width="200" alt="" />
          </NavLink>
        </div>
        {!isOpenNavResponsive && (
          <nav role="navigation" className="nav-menu w-nav-menu">
          {location.pathname !== '/join-team' 
            ? (
                <Link
                  activeClass="w--current"
                  to="hero-section"
                  spy={true}
                  smooth={true}
                  offset={-100}
                  duration={500}
                  isDynamic={true}
                  className="nav-btn w-nav-link"
                  onClick={() => {
                    setCurrentLink('hero-section')
                  }}
                >
                  { isES ? navbar.inicio.es : navbar.inicio.en }
                </Link>) 
            : (
              <NavLink
                className={({ isActive }) =>
                  isActive ? 'nav-btn w-nav-link w--current' : 'nav-btn w-nav-link'
                }
                to="/"
                onClick={async () => {
                  setCurrentLink('hero-section')
                  await sleep(0.2)
                  navigateWithScroll('/', 'hero-section')
                }}
              >
                { isES ? navbar.inicio.es : navbar.inicio.en }
              </NavLink>
            )
          }
          <Link
            activeClass={location.pathname !== '/join-team' && 'our-services' === currentLink ? 'w--current': ''}
            to={location.pathname !== '/join-team' ? 'our-services' : ''}
            spy={true}
            smooth={true}
            offset={-100}
            duration={500}
            className="nav-btn w-nav-link"
            isDynamic={true}
            onClick={() => {
              setCurrentLink('our-services')
              location.pathname === '/join-team' && navigateWithScroll('/', 'our-services');
            }}
          >
            { isES ? navbar.servicio.es : navbar.servicio.en }
          </Link>
          <Link
            activeClass={location.pathname !== '/join-team' && 'contact-us' === currentLink  ? 'w--current': ''}
            to={location.pathname !== '/join-team' ? 'contact-us' : ''}
            spy={true}
            smooth={true}
            offset={-100}
            duration={500}
            className="nav-btn w-nav-link"
            isDynamic={true}
            onClick={() => {
              setCurrentLink('contact-us')
              location.pathname === '/join-team' && navigateWithScroll('/', 'contact-us')
            }}
          >
            { isES ? navbar.contacto.es : navbar.contacto.en }
          </Link>
          <NavLink
            className={({ isActive }) =>
              isActive ? 'nav-btn w-nav-link w--current' : 'nav-btn w-nav-link'
            }
            to="join-team"
          >
            { isES ? navbar.aplica.es : navbar.aplica.en }
          </NavLink>
          <div 
            className="dropdown-menu w-dropdown" 
            onMouseEnter={() => setIsOpenLanguaje(true)} 
            onMouseLeave={() => setIsOpenLanguaje(false)}
          >
            <div className="language-dropdown w-dropdown-toggle">
              <div className="w-icon-dropdown-toggle"></div>
              <div>{isES ? 'ES' : 'EN'}</div>
            </div>
            <nav className={`languaje-list w-dropdown-list ${
              isOpenLanguaje 
                ? 'w--open' 
                : ''
              } `
            }>
              <NavLink 
                className="language-link w-dropdown-link"
                to={"/ES"||"/es"}
                onClick={() => {
                  
                  changeLanguaje(true)
                  setIsOpenLanguaje(false)
                  
                }}
              >
                <span className="language-abbreviation">ES</span>
                { isES ? navbar.languaje.spanish.es : navbar.languaje.spanish.en }
              </NavLink>
              <NavLink 
                className="language-link w-dropdown-link"
                to={"/EN"||"/en"}
                onClick={() => {
                  changeLanguaje(false)
                  setIsOpenLanguaje(false)
                }}
              >
                <span className="language-abbreviation">US</span>
                { isES ? navbar.languaje.english.es : navbar.languaje.english.en }
              </NavLink>
            </nav>
          </div>
        </nav>
        )}
        {/* icono de hamburguesa */}
        <div 
          className={`burger-menu w-nav-button ${isOpenNavResponsive ? 'w--open' : ''} `} 
          onClick={() => setIsOpenNavResponsive(!isOpenNavResponsive)}
        >
          <div className="w-icon-nav-menu"></div>
        </div>
      </div>
      <NavbarResponsive 
        isOpenLanguaje={isOpenLanguaje}
        isES={isES}
        location={location}
        navigateWithScroll={navigateWithScroll}
        open={isOpenNavResponsive}
        setIsOpenLanguaje={setIsOpenLanguaje}
        setIsOpenNavResponsive={setIsOpenNavResponsive}
        changeLanguaje={ ( val:boolean ) => changeLanguaje(val) }
      />
    </div>
  );
};

interface PropsNavReponsive {
  open          : boolean;
  isES          : boolean;
  location      : Location;
  isOpenLanguaje: boolean;

  navigateWithScroll: ( link: string, section: string, isActiveResponsive: boolean, offset?: number ) => void;
  setIsOpenLanguaje : React.Dispatch<React.SetStateAction<boolean>>,
  setIsOpenNavResponsive: React.Dispatch<React.SetStateAction<boolean>>,
  changeLanguaje: ( isSpanish: boolean ) => void;
}

const NavbarResponsive: FC<PropsNavReponsive> = ({
  open,
  location,
  isOpenLanguaje,
  isES,
  navigateWithScroll,
  setIsOpenLanguaje,
  setIsOpenNavResponsive,
  changeLanguaje,
}) => {

  const onClickLanguaje = ( openNavRes: boolean, isES: boolean ) => { 
    changeLanguaje(isES);
    setIsOpenNavResponsive(openNavRes);
  }

  return (
    
    <div 
      className="w-nav-overlay" 
      style={ open ? { display: 'block', height: '10505px'} : { display: 'none' } }
    >
      <nav 
        role="navigation" 
        className={`nav-menu w-nav-menu animate__animated ${open 
          ? 'animate__slideInDown animate__fast' 
          : ''} `
        } 
        style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
      >
        {location.pathname !== '/join-team' ? (
          <Link
            activeClass="w--current"
            to="hero-section"
            spy={true}
            smooth={true}
            offset={-100}
            duration={500}
            className="nav-btn w-nav-link w-nav-link-open"
            onClick={() => setIsOpenNavResponsive(!open)}
          >
            { isES ? navbar.inicio.es : navbar.inicio.en }
          </Link>
        ) : (
          <NavLink
            className={({ isActive }) =>
              isActive ? 'nav-btn w-nav-link w--current w-nav-link-open' : 'nav-btn w-nav-link w-nav-link-open'
            }
            to="/"
            onClick={() => setIsOpenNavResponsive(!open)}
          >
            { isES ? navbar.inicio.es : navbar.inicio.en }
          </NavLink>
        )}
        <Link
          activeClass={location.pathname !== '/join-team' ? 'w--current' : ''}
          to={location.pathname !== '/join-team' ? 'our-services' : ''}
          spy={true}
          smooth={true}
          offset={-100}
          duration={500}
          isDynamic={true}
          className="nav-btn w-nav-link w-nav-link-open"
          onClick={async() => {
            if (location.pathname === '/join-team') {
              navigateWithScroll('/', 'our-services', true)
            } else {
              setIsOpenNavResponsive(!open)
              await sleep(0.2)
              moveScroll('our-services');
              await sleep(0.1)
              navigateWithScroll('/', 'our-services', true, -10)
            }
          }}
        >
          { isES ? navbar.servicio.es : navbar.servicio.en }
        </Link>
        <Link
          activeClass={location.pathname !== '/join-team' ? 'w--current' : ''}
          to={location.pathname !== '/join-team' ? 'contact-us' : ''}
          spy={true}
          smooth={true}
          offset={-100}
          duration={500}
          className="nav-btn w-nav-link w-nav-link-open"
          onClick={() => {
            location.pathname === '/join-team' 
              ? navigateWithScroll('/', 'contact-us', true)
              : setIsOpenNavResponsive(!open)
          }}
        >
          { isES ? navbar.contacto.es : navbar.contacto.en }
        </Link>
        <NavLink
          className={({ isActive }) =>
            isActive 
            ? 'nav-btn w-nav-link w--current w-nav-link-open' 
            : 'nav-btn w-nav-link w-nav-link-open'
          }
          to="join-team"
          onClick={() => setIsOpenNavResponsive(!open)}
        >
          { isES ? navbar.aplica.es : navbar.aplica.en }
        </NavLink>
        <div
          className="dropdown-menu w-dropdown"
          onMouseEnter={() => setIsOpenLanguaje(true)}
          onMouseLeave={() => setIsOpenLanguaje(false)}
          style={{ width: '100%' }}
        >
          <div className="language-dropdown w-dropdown-toggle" style={{ width: '100%' }}>
            <div className="w-icon-dropdown-toggle"></div>
            <div>{isES ? 'ES' : 'EN'}</div>
          </div>
          <nav
            className={`languaje-list w-dropdown-list ${
              isOpenLanguaje ? 'w--open' : ''
            } `}
          >
            <NavLink 
              className="language-link w-dropdown-link" 
              to={"/ES"||"/es" }
              // onClick={() => setIsOpenNavResponsive(!open)}
              onClick={() => onClickLanguaje(!open, true)}
            >
              <span className="language-abbreviation">ES</span>
              { isES ? navbar.languaje.spanish.es : navbar.languaje.spanish.en }
            </NavLink>
            <NavLink 
              className="language-link w-dropdown-link" 
              to={"/EN" ||"/en"}
              // onClick={() => setIsOpenNavResponsive(!open)}
              onClick={() => onClickLanguaje(!open, false)}
            >
              <span className="language-abbreviation">US</span>
              { isES ? navbar.languaje.english.es : navbar.languaje.english.en }
            </NavLink>
          </nav>
        </div>
      </nav>
    </div>
  );
};