import { FC, useMemo, MouseEvent, useState } from 'react';
import { CountryType } from '../../interfaces';

type ClickBtnType = MouseEvent<HTMLButtonElement, globalThis.MouseEvent>;

interface Props {
  label       : string;
  defaultValue: string;
  countries   : CountryType[];

  onClick     : (valueSelecte: string) => void;
}

export const Dropdown: FC<Props> = ({
  label,
  countries,
  defaultValue,
  onClick,
}) => {

  const [open, setOpen] = useState(false);

  const valueSelected = useMemo(() => {
    return countries.find((country) => country.value === defaultValue);
    // eslint-disable-next-line 
  }, [defaultValue]);

  const onClickValue = (e: ClickBtnType, value: string) => {
    e.preventDefault();
    setOpen(false)
    onClick(value);
  };

  return (
    <>
      <label htmlFor="field" className="field-label">
        {label}
      </label>
      <div className="contact-field dropdown-field w-dropdown">
        <div
          className={`contact-field ${
            valueSelected?.classStyle || 'country'
          } w-dropdown-toggle`}
          onClick={() => setOpen(!open)}
        >
          <div className="w-icon-dropdown-toggle" />
          <div>{valueSelected?.text || ''}</div>
        </div>
        <nav className={`country-list w-dropdown-list ${open ? 'w--open' : ''}`}>
          {
            countries.map( country => (
              <button
                onClick={(e) => onClickValue(e, country.value)}
                className={`country-select ${country.classStyleOption} w-dropdown-link ${
                  valueSelected?.value === country.value ? 'acitve-flag' : ''
                }`}
                key={country.id}
              >
                {country.text}
              </button>
            ))
          }
        </nav>
      </div>
    </>
  );
};
