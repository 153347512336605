import {
  HomeSection,
  ServiceSection,
  ClientsSection,
  JobSection,
  ContactSection,
} from '../components';

export const DashbordView = () => {
  return (
    <>
      <HomeSection />
      <ServiceSection />
      <ClientsSection />
      <ContactSection />
      <JobSection />
    </>
  );
};
