import { languajes } from '../../helpers';
import { useAppSelector } from '../../store/hook';

const { service } = languajes;
const {
  main,
  customSoftware,
  business,
  personnel,
  consultancy,
  trainings,
  project,
} = service;

export const ServiceSection = () => {

  const { isES } = useAppSelector((state) => state.languaje);

  return (
    <section id="our-services" className="services-section wf-section">
      <div className="container-max w-container">
        <div className="info-columns w-row">
          <div className="column w-col w-col-4 w-col-stack">
            <div className="div-text">
              <h2 className="title-section white-bg">
                {isES ? main.title.our.es : main.title.our.en}
                <br />
                {isES ? main.title.service.es : main.title.service.en}
              </h2>
              <div>
                <p className="paragraph text-align">
                  {isES ? main.content.es : main.content.en}
                </p>
              </div>
            </div>
          </div>
          <div className="content-column w-col w-col-8 w-col-stack">
            <div>
              <div className="service-card">
                <img src="images/green_screen.svg" loading="lazy" alt="" />
                <div>
                  <h3 className="service-card-title">
                    {isES ? customSoftware.title.es : customSoftware.title.en}
                  </h3>
                  <p className="paragraph-2 text-align">
                    {isES ? customSoftware.content.es : customSoftware.content.en}
                  </p>
                  <p className="text-align">
                    {isES ? customSoftware.content2.es : customSoftware.content2.en}
                  </p>
                </div>
              </div>
              <div className="service-card">
                <img src="images/green_puzzle.svg" loading="lazy" alt="" />
                <div>
                  <h3 className="service-card-title">
                    {isES ? business.title.es : business.title.en}
                  </h3>
                  <p className="text-align">
                    {isES ? business.content.es : business.content.en}
                  </p>
                  <p className="text-align">
                    {isES ? business.content2.es : business.content2.en}
                  </p>
                </div>
              </div>
              <div className="service-card">
                <img src="images/green_users.svg" loading="lazy" alt="" />
                <div>
                  <h3 className="service-card-title">
                    {isES ? personnel.title.es : personnel.title.en}
                  </h3>
                  <p className="text-align">
                    {isES ? personnel.content.es : personnel.content.en}
                  </p>
                </div>
              </div>
              <div className="service-card">
                <img src="images/green_sitemap.svg" loading="lazy" alt="" />
                <div>
                  <h3 className="service-card-title">
                    {isES ? consultancy.title.es : consultancy.title.en}
                  </h3>
                  <p className="text-align">
                    {isES ? consultancy.content.es : consultancy.content.en}
                  </p>
                </div>
              </div>
              <div className="service-card">
                <img src="images/green_presentation-board.svg" loading="lazy" alt="" />
                <div>
                  <h3 className="service-card-title">
                    {isES ? trainings.title.es : trainings.title.en}
                  </h3>
                  <p className="text-align">
                    {isES ? trainings.content.es : trainings.content.en}
                  </p>
                </div>
              </div>
              <div className="service-card">
                <img src="images/green_border-style.svg" loading="lazy" alt="" />
                <div>
                  <h3 className="service-card-title">
                    {isES ? project.title.es : project.title.en}
                  </h3>
                  <p className="text-align">
                    {isES ? project.content.es : project.content.en}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
