import { AppRouter } from './routes/AppRouter';

import { store } from './store/store';
import { Provider } from 'react-redux';

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
// import './styles/app.css';
import './styles/normalize.css';
import './styles/webflow.css';
import './styles/itprofis.webflow.css';
import './styles/app.css';

const keyCaptcha = `${process.env.REACT_APP_reCAPTCHA_KEY_SITE}`;

export const App = () => {
  return (
    <Provider store={store}>
      <GoogleReCaptchaProvider
        reCaptchaKey={keyCaptcha}
        scriptProps={{ async: true, defer: true }}
      >
        <AppRouter />
      </GoogleReCaptchaProvider>
    </Provider>
  );
};
