import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useAppSelector } from "../../store/hook";
import CloseIcon from '@mui/icons-material/Close';
import "../../styles/app.css"
import { languajes } from '../../helpers/languajes'
const { qr, operation } = languajes;
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  
  p: 4,
};
const style2 = {
    cursor:"pointer",
    
  };
  
export default function BasicModal({ props }:{ props: string}) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { isES } = useAppSelector((state) => state.languaje);
  function cerrar(){
    setOpen(false)
  }
  return (
    <div >
    
      <Typography sx={style2} id="modal-modal-title" className='filtro' onClick={handleOpen}  >
      {isES ? qr.es : qr.en}
    </Typography>
    
    
   
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
       
        <Box sx={style}>
        <CloseIcon onClick={cerrar} style={{float:"right",marginBottom:"10px"}} />
          <img src={props} alt="" />
          
        </Box>
      </Modal>
    </div>
  );
}
