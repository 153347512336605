import { FC } from 'react';
import { useField } from 'formik';


interface Props  {
  label      : string;
  name       : string;
  className  : string;
  type?      : 'text' | 'email' | 'password' | 'number' | 'file';
  placeholder?: string;
  [x: string]: any;
}

export const TextInput: FC<Props> = ({ label, className, ...props }) => {
  const [ field, meta ] = useField(props);
  return (
    <>
      <label htmlFor="field" className="field-label">
        {label}
      </label>
      <input
        {...field}
        {...props}
        className={`${className} ${
          meta.touched && meta.error ? 'contact-field-error' : ''
        }`}
      />
       {
        meta.touched && meta.error 
        ? <span className="error-input">{meta.error}</span>
        : <></>
      }
    </>
  );
};
