import {createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
// import { RootState } from '../../store';

export interface LanguajeState {
  isES: boolean
};

const initialState: LanguajeState = {
  isES: true
};

const languajeSlice = createSlice({
  name: 'languaje',
  initialState,
  reducers: {
    setLanguaje: ( state, action: PayloadAction<boolean> ) => {
      state.isES = action.payload
    }
  }
});



/*
export const startlanguajeenticate = (): ThunkAction<void, RootState, unknown, AnyAction> => 
  async (dispatch, getState ) => {
    const { token } = getState().languaje;
    dispatch(setToke())
}
*/

export const { setLanguaje } = languajeSlice.actions;

export default languajeSlice.reducer;

