import { BrowserRouter, Route, Routes,HashRouter } from 'react-router-dom';
import { DashboardRoutes } from './DashboardRoutes';
import { DashbordView, JobView } from '../views';

export const AppRouter = () => {
  return (
    /*<BrowserRouter>*/

    <HashRouter >
      <Routes>
        <Route path="/*" element={<DashboardRoutes />} />
      </Routes>
    </HashRouter>
    
 /*</BrowserRouter>*/
  );
};
