import { ThunkAction } from 'redux-thunk';
import { RootState } from '../store/store';
import { AnyAction } from 'redux';

import { itProfisAPI } from './';
import { setToken } from '../store/slices/auth/authSlice';
import { sleep } from '../helpers';
import { setLoading } from '../store/slices/ui/uiSlice';

const user = `${process.env.REACT_APP_USER}`;
const password = `${process.env.REACT_APP_PASSWORD}`;

type ActionThunk = ThunkAction<void, RootState, unknown, AnyAction>;

export const authenticate =
  ( showLoading = true ): ActionThunk => async (dispatch) => {

    const params = {
      UserName: user,
      Password: password,
    };

    showLoading && dispatch(setLoading(true));
    await sleep(1);

    try {
      const resp = await itProfisAPI.post<ResponseLogin>('/api/accounts/signin', params);
     
      if (resp.data.Result) {
        const { Expires, Token } = resp.data.Response;
        dispatch(setToken({ expires: Expires, token: Token }));
      }

      showLoading && dispatch(setLoading(false));
      return {
        succes: true,
        error: resp.data?.Result ? 'autenticacion exitosa' : 'error al autenticar',
      };


    } catch (error) {
      showLoading && dispatch(setLoading(false));
      return { succes: false, error: 'error al autenticar' };

    }
  };

export interface ResponseLogin {
  Result: boolean;
  Response: Response;
}

export interface Response {
  Expires: string;
  Token: string;
}
