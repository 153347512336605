// import { AnyAction } from 'redux';
// import { ThunkAction } from 'redux-thunk';
// import { RootState } from '../store/store';

import { JobForm } from '../interfaces';
import { ContactForm } from '../interfaces/contact.interface';
import { itProfisAPIAuth } from './api';

// type ActionThunk = ThunkAction<void, RootState, unknown, AnyAction>;

export interface ResponseEmail {
  Result:   boolean;
  Response: Response | ResponseError;
}

export interface Response {
  ConfirmationMessage: string;
}

export interface ResponseError {
  Result:   boolean;
  Response: Response;
}

export interface Response {
  Errors: Error[];
}

export interface Error {
  Code:   string;
  Detail: string;
}


export const sendJobEmail = async ( jobForm: JobForm, token: string ) => {

  const cv = (jobForm.content as string)
  const api = itProfisAPIAuth(token);

  const params = {
    Tipo              : 'RRHH',
    NombreCompleto    : jobForm.fullName,
    CorreoElectronico : jobForm.email,
    Pais              : jobForm.country,
    Telefono          : jobForm.phoneNumber,
    Direccion         : jobForm.address,
    PretencionSalarial: jobForm.salary + '',
    Experiencia       : jobForm.skill,
    Curriculum        : cv.split(',')[1],
  };

  const result = { succes: false, msgEs: '', msgEn: '' }

  try {

    const resp = await api.post<ResponseEmail>('/api/emails/send', params);

    if ( resp.data.Result ){

      result.succes = true;
      result.msgEn = '';
      result.msgEs = '';
      return result;
    } else {
      const respError: ResponseError = resp.data.Response as ResponseError;

      result.succes = false;
      result.msgEn = 'Failed to send mail'
      result.msgEs = respError.Response.Errors[0].Detail;
      return result;
    }
    
  } catch (error) {
    result.succes = false;
    result.msgEn = 'Failed to send mail';
    result.msgEs = 'Error al enviar correo';
    return result;

  }
  

};

export const sendContactEmail = async ( contactForm: ContactForm, token: string ) => {

    const api = itProfisAPIAuth(token);
    
    
    const params = {
      Tipo             : 'CONTACTO',
      Nombre           : contactForm.name,
      CorreoElectronico: contactForm.email,
      Telefono         : contactForm.phoneNumber,
      Puesto           : contactForm.employment,
      Compania         : contactForm.company,
      Mensaje          : contactForm.message
    };

    const result = { succes: false, msgEs: '', msgEn: '' }

    try {

      const resp = await api.post<ResponseEmail>('/api/emails/send', params)
  
      if ( resp.data.Result ){
  
        result.succes = true;
        result.msgEn = '';
        result.msgEs = '';

      } else {
        const respError: ResponseError = resp.data.Response as ResponseError;
        
        result.succes = false;
        result.msgEn = 'Failed to send mail'
        result.msgEs = respError.Response.Errors[0].Detail;

      }
      return result;
      
    } catch (error) {
      result.succes = false;
      result.msgEn = 'Failed to send mail';
      result.msgEs = 'Error al enviar correo';
      return result;
  
    }
  
}