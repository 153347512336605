import { FormJob } from '../';
import { useAppSelector } from '../../store/hook';
import { languajes } from '../../helpers/languajes';

const { applyWithUs } = languajes;
const { look, location } = applyWithUs;

export const DetailJob = () => {
  const { isES } = useAppSelector((state) => state.languaje);

  return (
    <div className="join-us-section wf-section">
      <div className="container-max w-container">
        <div className="info-columns w-row">
          <div className="column contact-left w-col w-col-6 w-col-stack">
            <div>
              <div>
                <h2 className="title-section">{isES ? look.title.es : look.title.en}</h2>
                <p className="paragraph text-align">{isES ? look.content.es : look.content.en}</p>
                <p className="paragraph text-align">{isES ? look.content1.es : look.content1.en}</p>
                <div className="br" />
                <h2 className="join-team-title">
                  {isES ? location.es : location.en} Guatemala
                </h2>
                <div className="contact-info-div centered">
                  <img src="images/white_location.svg" loading="lazy" alt="" />
                  <a
                    href="https://goo.gl/maps/2zEUcmudTPK7qAsTA"
                    target="_blank"
                    className="contact-info-text"
                    rel="noopener noreferrer"
                  >
                    Vía 4, 1-00 Zona 4, Campus Tecnológico Torre 2, Guatemala
                  </a>
                </div>
                <div className="br" />
                <h2 className="join-team-title">
                  {isES ? location.es : location.en} El Salvador
                </h2>
                <div className="contact-info-div centered">
                  <img src="images/white_location.svg" loading="lazy" alt="" />
                  <a
                   href="https://www.google.com/maps/place/13%C2%B043'22.1%22N+89%C2%B013'45.4%22W/@13.7227041,-89.2291509,17z/data=!4m4!3m3!8m2!3d13.722792!4d-89.229271?hl=en-US" 

                    className="contact-info-text"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Reparto Santa Leonor, prolongación 75 Av. Norte, Polígono G, # 5, San
                    Salvador
                  </a>
                </div>
              </div>
            </div>
          </div>
          <FormJob />
        </div>
      </div>
    </div>
  );
};
