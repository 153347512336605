import { ReponseReCaptcha } from '../interfaces';
import { itProfisAPIAuth } from './api';


export const validateCaptchaToken = async ( token: string, tokenCaptcha: string ) => {
  const api = itProfisAPIAuth(token);
  
  const result = { succes: false, msgEs: '', msgEn: '', score: 0 }
  
  try {
    const res= await api.post<ReponseReCaptcha>('/api/captcha', { Token: tokenCaptcha });

    if ( res.data.success ) {

      result.succes = true;
      result.msgEs = '';
      result.msgEn = '';
      result.score = res.data?.score;

    } else {
      
      result.succes = false;
      result.score = 0;
      result.msgEs = 'Error al validar el captcha'
      result.msgEn = 'Error validating captcha'
    }
    
  } catch (error) {

    result.succes = false;
    result.score = 0;
    result.msgEs = 'Error al validar el captcha'
    result.msgEn = 'Error validating captcha'

  } finally {
    return result;
  }

};
