import { Link } from 'react-router-dom';
import { useAppSelector } from '../../store/hook';
import { languajes } from '../../helpers/languajes';

const { lookingJob } = languajes;
const { title, link } = lookingJob;
export const JobSection = () => {

  const { isES } = useAppSelector((state) => state.languaje);

  return (
    <section id="Aplica-con-nosotros" className="banners _02">
      <div className="container-max w-container">
        <h2 className="banner-text">{isES ? title.es : title.en }</h2>
        <Link className="link" to="join-team">
          {isES ? link.es : link.en }
        </Link>
      </div>
    </section>
  );
};
