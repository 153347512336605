import { useAppSelector } from "../../store/hook";
import { languajes } from '../../helpers/languajes';
import  BasicModal  from "../ui/BasicModal";
import QrCode2Icon from '@mui/icons-material/QrCode2';
import { useState } from 'react';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Typography from '@mui/material/Typography';
import "../../styles/app.css"
import { useRef } from 'react';
const { qr } = languajes;
const { sales, operation } = languajes;
export const DetailContact = () => {
  const imageRef = useRef<HTMLImageElement>(null);
  const { isES } = useAppSelector((state) => state.languaje);


  
  return (
    <div className="column contact-right w-col w-col-6 w-col-stack">
      <div>
        <h2 className="title-section white-bg">Guatemala</h2>
        <div className="div-contact">
          <div className="contact-info">
           
            <div className="contact-info-div">
              <img src="images/white_message.svg" loading="lazy" alt="" />
              
              <a
                href="mailto:ventas@itprofis-gt.com?subject=IT%20Profis%20%7C%20Ventas%20Guatemala"
                className="contact-info-text"
              >
                {isES ? sales.es : sales.en} : ventas@itprofis-gt.com
              </a>
            </div>
            <div className="contact-info-div">
              <img src="images/white_message.svg" loading="lazy" alt="" />
              <a
                href="mailto:operaciones@itprofis-gt.com?subject=IT%20Profis%20%7C%20Operaciones%20Guatemala"
                className="contact-info-text"
              >
              {isES ? operation.es : operation.en}:  operaciones@itprofis-gt.com
              </a>
            </div>
         
            <div className="contact-info-div">
              <img src="images/white_call.svg" loading="lazy" alt="" />
              <a href="tel:+50222319760" className="contact-info-text">
                PBX (+502) 2231 9760
              </a>
            </div>
          </div>
        </div>
        <div className="contact-info-div centered">
          <img src="images/white_location.svg" loading="lazy" alt="" />
          <a
            href="https://goo.gl/maps/2zEUcmudTPK7qAsTA"
            target="_blank"
            className="contact-info-text"
            rel="noopener noreferrer"
          >
            Vía 4, 1-00 Zona 4, Campus Tecnológico Torre 2, Guatemala
          </a>
        </div>
        <div className="contact-info-div" id="movil">
         <QrCode2Icon/>
        
        <BasicModal props={"images/thumbnail_Image-1.jpg"}/>
         
        </div>
        <div className="contact-info-div" id="filtroMovil">
        <QrCode2Icon/>
              <a
                 href="https://wa.me/message/4GQQN2FDCLZVE1"
                className="contact-info-text"
              >
              {isES ? qr.es : qr.en}
              </a>
            </div>
        <div className="br" />
        <h2 className="title-section white-bg">El Salvador</h2>
        <div className="div-contact">
          <div className="contact-info">
           
            <div className="contact-info-div">
              <img src="images/white_message.svg" loading="lazy" alt="" />
              <a
                href="mailto:operaciones@itprofis-gt.com?subject=IT%20Profis%20%7C%20Operaciones%20El%20Salvador"
                className="contact-info-text"
              >
              {isES ? operation.es : operation.en}:  operaciones@itprofis-gt.com
              </a>
            </div>
            <div className="contact-info-div">
              <img src="images/white_message.svg" loading="lazy" alt="" />
              <a
                href="mailto:ventas@itprofis-gt.com?subject=IT%20Profis%20%7C%20Ventas%20El%20Salvador"
                className="contact-info-text"
              >
               {isES ? sales.es : sales.en}: ventas@itprofis-gt.com
              </a>
            </div>
            <div className="contact-info-div">
              <img src="images/white_call.svg" loading="lazy" alt="" />
              <a href="tel:+50322170270" className="contact-info-text">
                PBX (+503) 2217 0270
              </a>
            </div>
          </div>
        
        </div>
        <div className="contact-info-div centered">
          <img src="images/white_location.svg" loading="lazy" alt="" />
          <a 
            href="https://www.google.com/maps/place/13%C2%B043'22.1%22N+89%C2%B013'45.4%22W/@13.7227041,-89.2291509,17z/data=!4m4!3m3!8m2!3d13.722792!4d-89.229271?hl=en-US" 
            className="contact-info-text"
            target="_blank"
            rel="noopener noreferrer"
          >
            Reparto Santa Leonor, prolongación 75 Av. Norte, Polígono G, # 5, San Salvador
          </a>
        </div>
        <div className="contact-info-div " id="movil">
         <QrCode2Icon/>
        
        <BasicModal props={"images/thumbnail_Image-1.jpg"} />
     
         
        </div>
        <div className="contact-info-div" id="filtroMovil">
        <QrCode2Icon/>
              <a
                 href="https://wa.me/message/4GQQN2FDCLZVE1"
                className="contact-info-text"
              >
              {isES ? qr.es : qr.en}
              </a>
            </div>
       
        <div className="br" />
        <div className="contact-social-media">
          <a
            href="https://www.facebook.com/ITProfis.gt"
            target="_blank"
            className="social-media-link w-inline-block"
            rel="noopener noreferrer"
          >
            <img
              src="images/white_facebook.svg"
              loading="lazy"
              width={24}
              height={24}
              alt=""
            />
          </a>
          <a
            href="https://www.linkedin.com/company/itprofis-gt/"
            target="_blank"
            className="social-media-link w-inline-block"
            rel="noopener noreferrer"
          >
            <img src="images/linkedin.svg" loading="lazy" width={24} height={24} alt="" />
          </a>
          <a
             href="https://wa.me/message/4GQQN2FDCLZVE1"
            target="_blank"
            className="social-media-link w-inline-block"
            rel="noopener noreferrer"
          >
            <WhatsAppIcon sx={{ fontSize: 30,color:"#fff" }}/>
            
          </a>
        </div>
      </div>
    </div>
  );
};
