
import { useState, useCallback } from 'react';

import { FormikHelpers } from 'formik';

import { ContactForm } from '../interfaces';
import { getSubstractDate, sleep } from '../helpers';
import { useAppDispatch, useAppSelector } from '../store/hook';
import { languajes } from '../helpers/languajes';
import { authenticate } from '../api/authApi';

// import ReCAPTCHA from 'react-google-recaptcha';
import { validateCaptchaToken } from '../api/recaptchaApi';
import { sendContactEmail } from '../api/mailApi';

type HelperFomik = FormikHelpers<ContactForm>;
type KeyForm =  'name' | 'email' | 'phoneNumber' | 'company' |'message' | 'employment';

const initialValues: ContactForm = {
  name       : '',
  email      : '',
  phoneNumber: '',
  company    : '',
  message    : '',
  employment : '',
}



// const keyCaptcha = `${process.env.REACT_APP_reCAPTCHA}`
const { contact } = languajes;
const { form } = contact;

const initialValuesError = { error: false, msg: { es: '', en: ''} };

export const useFormContact = () => {

  const [respError, setRespError] = useState(initialValuesError);
  const [showSuccess, setShowSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [token, setToken] = useState<string>('');
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);


  const { isES } = useAppSelector((state) => state.languaje);
  const { token: tokenAuth, expires } = useAppSelector( state => state.auth );
  const dispatch = useAppDispatch();



  const getLabelByLanguaje = useCallback(( key: KeyForm ) => {
    return isES ? form[key].label.es : form[key].label.en;
  }, [isES]);

  const getPlaceholderByLanguaje = useCallback(( key: KeyForm ) => {
    return isES ? form[key].placeholder.es : form[key].placeholder.en;
  }, [isES]);

  const getTextAux = useCallback(( type: 'bttn' | 'msgSucces' ) => {
    if ( type === 'bttn' )
      return isES ? form.button.es : form.button.en;
      return isES ? form.msgSucces.es : form.msgSucces.en;
  }, [isES]);

  const setTimeOutSucces = async () => {
    if ( showSuccess ) {
      await sleep(7);
      setShowSuccess(false)
    } 
  }

  const setTimeOutError = async () => {
    if ( respError.error ) {
      await sleep(5);
      setRespError(initialValuesError);
    } 
  }
  const onVerify = useCallback((tokenCaptcha: string) => {
    setToken(tokenCaptcha);
  }, []);

  const onSubmit = async ( values: ContactForm, actions: HelperFomik  ) => {

    setIsLoading(true);

    await renewSession();

    const resp = await validateCaptchaToken(tokenAuth, token);

    if ( !resp.succes ) {
      setIsLoading(false);
      setRespError({ error: true, msg: { es: resp.msgEs, en: resp.msgEn } });
      return;
    }

    if ( resp.score < 0.8 ) {
      setIsLoading(false);
      setRespError({ error: true, msg: { es: 'Captcha invalido', en: 'Invalid Captcha' } });
      return;
    }

    const respEmail = await sendContactEmail(values, tokenAuth);

    if ( !respEmail.succes ) {
    
      setIsLoading(false);
      setRespError({ error: true, msg: { es: respEmail.msgEs, en: respEmail.msgEn } });
      return;
    }
    setRefreshReCaptcha( r => !r);
    setRespError(initialValuesError);
    actions.resetForm();
    setIsLoading(false);
  
    setShowSuccess(true);
    return;

  }

  const renewSession = async () => {
    if ( getSubstractDate(expires) ) await dispatch(authenticate(false));
  }

  
  return {

    initialValues,
    isLoading,
    showSuccess,
    isES,
    refreshReCaptcha,
    respError,

    onSubmit,
    setTimeOutSucces,
    getLabelByLanguaje,
    getPlaceholderByLanguaje,
    getTextAux,
    setTimeOutError,
    onVerify,
  };
}
