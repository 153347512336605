import { ApplyJob, DetailJob } from '../components';
import { useEffect } from 'react';
import { moveScroll } from '../helpers';

export const JobView = () => {

  useEffect(() => {
    moveScroll('jobview');
  }, []);
  
  return (
    <div id="jobview">
      <ApplyJob />
      <DetailJob />
    </div>
  );
};
