import { useEffect, useState } from 'react';

import { Formik, Form } from 'formik';
import { TextInput, TextArea, Dropdown } from '..';
import { useFormJob } from '../../hooks';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { 
  languajes, 
  validateSchemaFormJobEN, 
  validateSchemaFormJobES 
} from '../../helpers';
import { SpinnerBttn } from '../../animation';

// import ReCAPTCHA from 'react-google-recaptcha';


const { applyWithUs } = languajes;
const { formTitle } = applyWithUs;

export const FormJob = () => {


  const {
    // captcha,
    // captchaError,
    countries,
    fileError,
    initialValues,
    isLoading,
    isES,
    showSuccess,
    // validateSchema,
    refreshReCaptcha,
    respError,

    onChangeInputFile,
    onChangeSelect,
    onSubmit,
    setTimeOutSucces,
    setTimeOutError,
    onVerify,
    getLabelByKey,
    getPlaceholderByKey,
    getTextAux,
    // getFlagByCountry,
  } = useFormJob();
  
  const [renderInput, setRenderInput] = useState(true)

  useEffect(() => {
    setTimeOutSucces()
    setRenderInput(false)
    // eslint-disable-next-line 
  }, [showSuccess]);

  useEffect(() => {
    setTimeOutError();
    // eslint-disable-next-line 
  }, [respError.error])

  useEffect(() => {
    if(!renderInput){
      setRenderInput(true)
    }
  }, [renderInput])

  return (
    <div className="column contact-right w-col w-col-6 w-col-stack" id='form-job'>
      <div className="w-form">
        <h2 className="join-team-title">{isES ? formTitle.es : formTitle.en}</h2>
        <Formik 
          initialValues={initialValues} 
          onSubmit={onSubmit}
          validationSchema={isES ? validateSchemaFormJobES : validateSchemaFormJobEN}
        >
          {({ values, setFieldValue }) => (
            <Form noValidate>
              <TextInput
                name="fullName"
                type='text'
                className="contact-field name w-input"
                placeholder={getPlaceholderByKey('fullName')}
                label={getLabelByKey('fullName')}
              />
              <TextInput 
                name="email"
                type='email'
                className="contact-field email w-input"
                placeholder={getPlaceholderByKey('email')}
                label={getLabelByKey('email')}
              />

              <Dropdown 
                countries={countries}
                defaultValue={values.country}
                label={getLabelByKey('country')}
                onClick={ valueSelect => onChangeSelect({ 
                    inputName: 'country', 
                    inputValue: valueSelect, 
                    setFieldValue
                  })
                }
                key={'formjob-1'}
              />

              <TextInput 
                name="phoneNumber"
                type="text"
                className="contact-field phone w-input"
                placeholder={getPlaceholderByKey('phoneNumber')}
                label={getLabelByKey('phoneNumber')}
              />
              
              <TextInput 
                name="address"
                type="text"
                className="contact-field direction w-input"
                placeholder={getPlaceholderByKey('address')}
                label={getLabelByKey('address')}
              />
              <TextInput 
                name="salary"
                type="number"
                className={`contact-field ${
                    values.country === 'Guatemala'
                      ? 'salary-quetzal'
                      : 'salary'
                  } w-input`
                }
                placeholder={`${getPlaceholderByKey('salary')} ${
                    values.country === 'Guatemala'
                      ? 'quetzales'
                      : 'dólares'
                  })`
                }
                // label="Pretención Salarial *"
                label={getLabelByKey('salary')}
              />
              <TextArea
                name="skill"
                className="contact-field message w-input"
                placeholder={getPlaceholderByKey('skill')}
                label={getLabelByKey('skill')}
              />
              {renderInput && (
                <TextInput 
                name="content"
                type="file"
                onChange={ (e: any) => onChangeInputFile(e, setFieldValue)}
                accept = 'application/pdf'
                value = {undefined}
                className={`contact-field file w-input ${
                  fileError.error 
                    ? 'contact-field-error' 
                    : ''
                }`}
                placeholder={getPlaceholderByKey('content')}
                label={getLabelByKey('content')}
              />
              )}
              {fileError.error && (
                <span className="error-input">{isES ? fileError.msg.es : fileError.msg.en}</span>
              )}

              <div 
                style={{ 
                  width: '100%', 
                  marginBottom: '10px', 
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                 <GoogleReCaptcha
                  onVerify={onVerify}
                  refreshReCaptcha={refreshReCaptcha}
                />

                {/* {captchaError && (
                  <span className="error-catpcha">Por favor acepte el captcha</span>
                )} */}
              </div>
              {!showSuccess && (
                <button 
                  className="button w-inline-block" 
                  style={{ width: '100%' }} 
                  type="submit"
                  disabled={isLoading}
                >
                  {isLoading ? <SpinnerBttn isES={isES} /> : getTextAux('bttn')}
                  
                  {!isLoading && (
                    <img 
                      src="images/white_right-arrow.svg" 
                      loading="lazy" 
                      width={24} 
                      alt="" 
                    />
                  )}
                </button>
              )}
            </Form>
          )}

        </Formik>
        {showSuccess && !respError.error && (
          <div className="w-form-done" style={{ marginTop: '10px' }}>
            <div>{getTextAux('msgSucces')}</div>
          </div>
        )}
        {respError.error && (
         <div className="w-form-fail" style={{ marginTop: '10px', textAlign: 'center' }}>
          <div>{isES ? respError.msg.es : respError.msg.en}</div>
        </div>
        )}
      </div>
    </div>
  );
};
